@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";
// @import './base/custom-rtl.scss';

// React Specific
@import "react/index";
@layer utilities {
    .flex-1-1-50 {
      flex: 1 1 50%;
    }
  }